@tailwind base;
@tailwind components;
@tailwind utilities;

/* Leaflet (HomeLocations component) requires its own CSS file for styling */
@import url("https://unpkg.com/leaflet@1.7.1/dist/leaflet.css");

html {
  scroll-behavior: smooth;
}
